$(window).scroll(function() {
  var url = $('.pagination a[rel="next"]').attr('href');
  var scrollDownHeight;

  if (screen.width < 640) {
    scrollDownHeight = 3000;
  } else {
    scrollDownHeight = 900;
  }

  if (url && $(window).scrollTop() > ($(document).height() - $(window).height() - scrollDownHeight)) {
    $('.pagination').text("");
    $.getScript(url)
  }
});

